<template>
  <div class="editData">
    <el-dialog
      title="审核处理"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="close"
    >
    <el-form :rules="rules" ref="form">
        <el-form-item label="审核结果" prop="sta">
            <el-select
                v-model="form.sta"
                placeholder="请选择"
                style="width: 180px"
            >
                <el-option label="通过" value="20"></el-option>
                <el-option label="拒绝" value="30"></el-option>
            </el-select>
        </el-form-item>
    </el-form>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {boxordersta,boxorderstalist} from "@/request/api";

export default {
  name: "AddDialog",
  components: {},
  data() {
      return {
          form: {
            sta: '20',
        },
    
     id:'',
     dialogVisible:false,
      // rules: {         
      //   sta: [{ required: true, message: "请选择审核结果", trigger: "Change" },], 
      // },
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {
   
    show(id) {
        console.log(id)
        this.id = id
        this.dialogVisible=true
    },
    close() {
       this.id=''
       this.form.sta='20'
       this.dialogVisible = false;
    },
      submitForm(form) {
        if (this.sta != '') {
            let params = {
            token: sessionStorage.getItem("token"),
            id: this.id,
            sta:this.form.sta
          }
            boxordersta(params).then((res) => {
              console.log(params,'1233')
            
              console.log(res,'56565666')
              if (res.data.code == 200) {
                this.$message.success("操作成功")
                this.close()
                this.$parent.getUserList()
              }
              else {
                this.$message.error(res.data.msg)
            }
        })
        }
          
      
    },
   
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.xian {
  margin-left: -75px !important;
}
.userif {
  margin-top: 100px;
}
</style>
