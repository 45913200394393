<template>
  <div class="user">
   <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="用户ID" >
          <el-input
            style="width: 180px"
            v-model="uid"
            clearable
            placeholder="请输入用户ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品ID" >
          <el-input
            style="width: 180px"
            v-model="goodsId"
            clearable
            placeholder="请输入商品ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" >
          <el-select
            v-model="sta"
            placeholder="请选择"
            style="width: 180px"
          >
            <el-option label="全部状态" value=""></el-option>
            <el-option label="待审核" value="10"></el-option>
            <el-option label="已通过" value="20"></el-option>
            <el-option label="拒绝" value="30"></el-option>
          </el-select>
        </el-form-item>
       
        <el-form-item style="float: right">
            <el-upload
            ref="upload"
            action="#"
            :before-upload="handleChange"
            accept=".xlsx"
            :limit="1"
          >
            <el-button @click="clearFiles" icon="el-icon-plus" type="primary"
              >点击上传</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item style="float: right">
            <el-button  icon="el-icon-download" type="primary">
            <a
              style="
                text-decoration: none;
                outline: none;
                color: #fff;
                width: 100%;
                height: 100%;
              "
              href="https://h5.yujian02.xyz/订单导入模板.xlsx"
              download="订单模版下载.xlsx"
              >模板下载</a
            >
          </el-button>
        </el-form-item>
         <el-form-item style="float: right">
          <el-button type="primary" @click="add(1,'')">添加</el-button>  
          <el-button type="primary" @click="search()">搜索</el-button>
        </el-form-item >
      </el-form>
    </div>

    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="id" label="ID" align="center">
      </el-table-column> -->
      <el-table-column prop="uid" label="用户ID" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="手机号" align="center">
      </el-table-column>
      <el-table-column prop="goods_id" label="商品ID" align="center">
      </el-table-column>
       <el-table-column prop="name" label="商品名称" align="center">
      </el-table-column>
      <!-- <el-table-column prop="rebate" label="购买区域" align="center">
        <template slot-scope="scope">
          <el-link  v-if="scope.row.rebate == 1">沈阳</el-link>
          <el-link  v-if="scope.row.rebate == 2">北京</el-link>
        </template>
      </el-table-column> -->
       <el-table-column prop="rebate" label="有无佣金" align="center">
        <template slot-scope="scope">
          <el-link  v-if="scope.row.rebate == 1">有</el-link>
          <el-link  v-if="scope.row.rebate == 2">无</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="sta" label="状态" align="center">
        <template slot-scope="scope">
          <el-link type="warning" v-if="scope.row.sta == 10">待审核</el-link>
          <el-link type="success" v-else-if="scope.row.sta == 20">已通过</el-link>
          <el-link type="danger" v-else-if="scope.row.sta == 30">拒绝</el-link>
          <el-link type="danger" v-else>无</el-link>

        </template>
      </el-table-column>
       <el-table-column prop="buytype" label="购买方式" align="center">
        <template slot-scope="scope">
          <el-link  v-if="scope.row.buytype == 1">线上</el-link>
          <el-link  v-if="scope.row.buytype == 2">线下</el-link>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="created_at" label="创建时间" align="center">
      </el-table-column> -->
      <el-table-column prop="owner" label="经理人" align="center">
      </el-table-column>
     <!-- <el-table-column prop="branch" label="分支机构" align="center">
      </el-table-column> -->
      <el-table-column prop="detail" label="备注" align="center">
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" align="center">
      </el-table-column>
      <!-- <el-table-column prop="deleted_at" label="发放时间" align="center"> 
      </el-table-column> -->
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <el-button type="text" size="small" @click="add(2,scope.row)">编辑</el-button> -->
          <el-button type="text" size="small" @click="showDetail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </page-table>
    <!-- 新增编辑弹窗 -->
    <!-- <edit-data ref="editData"></edit-data> -->
    <AddDialog ref="AddDialog"></AddDialog>
    <orderDetail ref="orderDetail"></orderDetail>
    <staDialog ref="staDialog"></staDialog>
  </div>
</template>

<script>
import { boxorderList } from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import AddDialog from './components/addDialog';
import orderDetail from './components/orderDetail'
import staDialog from './components/staDialog'
import axios from "axios";

export default {
  components: {
    pageTable,
    AddDialog,
    orderDetail,
    staDialog
  },
  data() {
    return {
        userList: [], // 用户列表
        uid: '',
        goodsId: '',
        sta: '',
        showAdd:false,
        page: {
            //分页信息
            currentPage: 1, //当前页
            pageSize: 10, //每页条数
            total: 0, //总条数
      },
      fileName: "", //Excel文件
        
    };
  },
  watch: {
    // time(newVal) {
    //   if (newVal == null) {
    //     this.time = [];
    //   }
    // },
  },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {
    // console.log(this.$server.api);
  },
  computed: {},
  methods: {
     // 上传
    chenggong() {
      let fileFormData = new FormData();
      fileFormData.append("file", this.fileName);
      fileFormData.append("token", sessionStorage.getItem("token"));
      axios({
        method: "POST",
        url: this.$server.api + "/sell/orderExcel",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: fileFormData,
      });
    },
    handleChange(file) {
      // console.log(file)
      this.fileName = file;
      // 上传格式
      if (file.name.split(".")[1] !== "xlsx") {
        this.$message.warning("上传文件只能是 xlsx格式!");
        return;
      }
      // 选择是否上传
      this.$confirm("是否确认上传改文件", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.chenggong();
          this.$message({
            type: "success",
            message: "上传成功",
          });
          this.getUserList()
        })
        .catch((e) => {
          // console.log(e);
          this.$refs.upload.clearFiles();
          this.$refs.upload.abort();
          this.$message({
            type: "info",
            message: "已取消上传",
          });
        });
      return false; // 返回false不会自动上传
    },
    clearFiles() {
      this.$refs.upload.clearFiles();
    },
    // add() {
    //   this.$refs.editData.show();
    // },
    add(type, row) {
      let typeTitle =type 
       let rowData = row;
       // 1:新增，2:编辑
       this.$refs.AddDialog.show(typeTitle, JSON.parse(JSON.stringify(rowData)));
     },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },

    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
        let params = {
        token: this.token,
        page:this.page.currentPage,
        limit: this.page.pageSize,
        uid: this.uid,
        goods_id: this.goodsId,
        sta:this.sta
        }
        boxorderList (params).then((res) => {
        // console.log(res)
        this.userList = res.data.data
        this.page.total=res.data.count
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      })
    },
    search() {
      // console.log('搜索')
      this.page.currentPage = 1
      this.getUserList()
      },
    // 启用/禁用
    changeSwitch(row){
        // console.log(row)
        let params = {
            token: sessionStorage.getItem("token"),
            id: row.id,
            sta:row.sta
        }
        owneredit(params).then((res) => {
            // console.log(res)
            if (res.data.code == 200) {
                this.$message.success("操作成功");
            }
            else {
                this.$message.error(res.data.msg);
              }
        })

      },
    //查看详情
      showDetail(row) {
       console.log(row,'aaa')
        this.$refs.orderDetail.show(JSON.parse(JSON.stringify(row)))
    },
    //改变审核状态
    changeSta(row) {
      this.$refs.staDialog.show(row.id)
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
