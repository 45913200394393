<template>
  <div class="editData">
    <el-dialog
      :title="type==1 ?'新增':'修改'"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form label-width="auto" :rules="rules" ref="form" :model="form">
        <!-- <el-row>
          <el-col :span="10" class="userif" v-if="this.type==2"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="ID" prop="id">
                <el-input v-model="form.id" style="width: 180px" disabled></el-input
              ></el-form-item></div
          ></el-col>
        </el-row> -->
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="用户ID" prop="uid">
                <el-input v-model="form.uid"  style="width: 180px"  @blur="getMessage(form.uid)"></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>
         <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light" >
              <el-form-item label="商品ID" prop="goodsId">
                  <el-select
                      v-model="form.goodsId"
                      placeholder="请选择"
                      style="width: 180px"
                      @change="changeSelect(form.goodsId)"
                    >
                      <el-option v-for="item in goodsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>                  
              </el-form-item>
              </div>
            </el-col>
        </el-row>
         <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="form.phone"  style="width: 180px"></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple" >
              <el-form-item label="分支机构" prop="branch">
                <el-select
                  style="width: 180px"
                  v-model="form.branch"
                  clearable
                  placeholder="请选择分支机构"
                  @change="changeBranch"
                >
                  <el-option
                  v-for="item in branchList"
                  :key="item.id"
                  :label="item.area"
                  :value="item.id">
                </el-option>
                </el-select>
              </el-form-item></div
          ></el-col>
        </el-row>    
         <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="领导人" prop="owner">
                <el-input v-model="form.owner"  style="width: 180px"></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="备注" prop="detail">
                <el-input v-model="form.detail"  style="width: 180px"></el-input
              ></el-form-item></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple" >
              <el-form-item label="是否有佣金" prop="rebate">
                <el-select
                  style="width: 180px"
                  v-model="form.rebate"
                  clearable
                  placeholder="请选择是否有佣金"
                >
                  <!-- <el-option label="请选择分类" :value="0"></el-option> -->
                  <el-option label="有" value="1"></el-option>
                  <el-option label="无" value="2"></el-option>
                </el-select>
              </el-form-item></div
          ></el-col>
        </el-row>  
        <!-- <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple" >
              <el-form-item label="安装地区" prop="areaId">
                <el-select
                  style="width: 180px"
                  v-model="form.areaId"
                  clearable
                  placeholder="请选择安装地区"
                  @change="areaChange"
                >
                <el-option v-for="item in areaArr" :value="item.id" :label="item.area" :key="item.id"></el-option>
                </el-select>
              </el-form-item></div
          ></el-col>
        </el-row> -->
        <!-- <el-row>
          <el-col :span="12"
            ><div class="grid-content bg-purple" >
              <el-form-item label="购买区域" prop="rebate">
                <el-select
                  style="width: 180px"
                  v-model="form.rebate"
                  clearable
                  placeholder="请选择购买区域"
                >
                  <el-option label="有" value="1"></el-option>
                  <el-option label="无" value="2"></el-option>
                </el-select>
              </el-form-item></div
          ></el-col>
        </el-row>         -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { boxorderadd,boxuidPhone,boxuidowner,goodsindex,branchList,areaList  } from "@/request/api";

export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
      isClear: false,
      loading: false,
      goodsList:[],
      type:'',
        form: {
            id: '',
            goodsId:'',
            uid:'',
            rebate: '',
            phone: '',
            detail: '',
            owner:'',
            branch:'',
            areaId:1,
            },
            areaArr:[],

       dialogVisible:false,
      branchList:[],//分支机构列表
      rules: {
        uid: [
          {
            required: true,
            message: "请填写用户ID",
            trigger: "blur",
          },
        ],
        goodsId: [
          {
            required: true,
            message: "请填写商品ID",
            trigger: "blur",
          },
        ],
        rebate: [{ required: true, message: "请选择是否有佣金", trigger: "blur" },],
        phone: [{required: true, message: "请填写手机号",  trigger: "blur"},],
        branch: [{required: true, message: "请选择分支机构",  trigger: "blur"},],
        // detail: [{required: true, message: "请填写备注",  trigger: "blur"},],
        // owner: [{required: true, message: "请填写领导人",  trigger: "blur"},],   
      },
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {
   
      show(type, row) {
      this.type=type
      // console.log(type,row,'5665665')
      if (this.type == 2) {
        this.dialogVisible = true;
        this.form.id=row.id
        this.form.uid = row.uid
        this.form.goodsId=row.goodsId
        this.form.rebate=row.rebate
        this.form.detail=row.detail
        this.form.owner=row.owner
        this.form.branch=row.branch
        }
      else {
       this.dialogVisible = true;
      }
      this.getUserList()
      this.getBranch()
      this.getArea()
    },
    close() {
       this.dialogVisible = false;
       this.form.id=''
       this.form.uid = ''
        this.form.goodsId=''
        this.form.rebate=''
        this.form.detail=''
      this.form.owner = ''
      this.form.phone=''
      this.form.branch=""
    },
    change(val) {
      this.form.detail = val;
      },
    //输入用户ID得到手机号和领导人
      getMessage() {
          this.$refs.form.validateField(["uid"] ,(valid) => {
              if (!valid) {
                    this.getPhone()
                    this.getOwner()
            }
          })
             
      },
    //   得到手机号
    getPhone() {
        let params = {
            token: sessionStorage.getItem("token"),
            uid:this.form.uid
        }
        boxuidPhone(params).then((res) => {
            this.form.phone=res.data.data
        })
      },
     //   得到领导人
    getOwner() {
        let params = {
            token: sessionStorage.getItem("token"),
            uid:this.form.uid
        }
        boxuidowner(params).then((res) => {
            this.form.owner=res.data.data
        })
    },
    //得到地区
    getArea(){
      let params={}
      areaList(params).then((res)=>{
        // console.log(res,'地区1111111111')
        this.areaArr=res.data.data
      })
    },
    areaChange(val){
      this.form.areaId=val
    },
    //获取分支机构
    getBranch(){
      let params={
        token:sessionStorage.getItem("token")
      }
      branchList(params).then((res)=>{
            console.log(res,'1111111111111111')
            this.branchList=res.data.data
      })
    },
    //选择分支机构之后显示uid
    changeBranch(e){
      if(e!==1){
        this.form.owner=this.branchList.filter(item=>item.id==e)[0].uid   
      }
    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid == true) {
          if (this.type == 1) {
            let params = {
            token: sessionStorage.getItem("token"),
            uid: this.form.uid,
            goods_id: this.form.goodsId,
            rebate: this.form.rebate,
            phone: this.form.phone,
            detail: this.form.detail,
            owner: this.form.owner,
            branch:this.form.branch,
            area:this.form.areaId,
            }
            boxorderadd(params).then((res) => {
              console.log(params,'2222')
              if (res.data.code == 200) {
                this.$parent.getUserList()
                this.$message.success("新增成功");
              }
              else {
                this.$parent.getUserList()
                this.$message.error(res.data.msg);
                }
            })
          this.close()           
          }
          else {
            let params = {
                id:this.form.id,
                token: sessionStorage.getItem("token"),
                uid: this.form.uid,
                goods_id: this.form.goodsId,
                rebate: this.form.rebate,
                phone: this.form.phone,
                detail: this.form.detail,
                owner: this.form.owner,
                branch:this.form.branch,
                area:this.form.areaId,
            }
            boxorderadd(params).then((res) => {
              if (res.data.code == 200) {
                this.$parent.getUserList()
                this.$message.success("编辑成功");

              }
              else {
                this.$parent.getUserList()
                this.$message.error(res.data.msg);
                  
                }
            })
           this.close()              
          }
          
        }
     })
    },
    // 拿到商品id和商品名称
    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
        let params = {
        token: this.token,
        page:1,
        limit: 1000,
        }
      goodsindex(params).then((res) => {
        this.goodsList = res.data.data
      })
    },
    // // 改变select的值
    // changeSelect(goodsId) {
    //   console.log(goodsId)
    //   this.goodsList.map(item=>{
    //     console.log(item)
    //     if (goodsId == item.id) {
    //       this.
    //     }
    //   })
    // }
   
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.xian {
  margin-left: -75px !important;
}
.userif {
  margin-top: 100px;
}
</style>
