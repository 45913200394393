<template>
  <div class="editData">
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="close"
    >
     <page-table
      ref="dataTable"
      :data="orderInfo"
    >
      <!-- <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column> -->

      <el-table-column prop="id" label="ID" align="center">
      </el-table-column>
      <el-table-column prop="pay_price" label="支付价格" align="center">
      </el-table-column>
      <el-table-column prop="money" label="售价" align="center">
      </el-table-column>
      <el-table-column prop="install_priece" label="安装费" align="center">
      </el-table-column>
      <el-table-column prop="order_time" label="订单时间" align="center">
      </el-table-column>
      <el-table-column prop="own_order_id" label="订单号" align="center">
      </el-table-column>
      <el-table-column prop="branch" label="分支机构" align="center">
      </el-table-column>
      <el-table-column prop="transaction_id" label="微信支付交易号" align="center">
      </el-table-column>
      <el-table-column prop="pay_time" label="支付时间" align="center">
      </el-table-column>
    </page-table>
      
    </el-dialog>
  </div>
</template>

<script>
import { boxorderInfo} from "@/request/api";
import pageTable from "@/components/pageTable.vue";

export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
      isClear: false,
      loading:false,
      id:'',
      dialogVisible: false,
      orderInfo:[],
      branch:""
     
    };
  },
  components: {
    pageTable,
    
  },
  created: function () {},
  mounted () {
    // this.getOrderDetail()
  },
  methods: {
    show(row) {
      // console.log(row,'1111111111111111111111111111111')
      this.id = row.id
      this.branch=row.branch
      this.getOrderDetail()
      this.dialogVisible = true
     
    },
    close() {
       this.dialogVisible = false;
       this.id=''
      },
    //得到商品详情
      getOrderDetail() {
          let params = {
            token: sessionStorage.getItem("token"),
            id:this.id
          }
          boxorderInfo(params).then((res) => {
            // console.log(res,'7777777777')
            //  let orderObj=res.data.data
            // orderObj["branch"]=this.branch
            this.orderInfo = [res.data.data]
            this.$refs.dataTable.setPageInfo({
            total: this.orderInfo.length,
        });
        })
        
    }
   
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.xian {
  margin-left: -75px !important;
}
.userif {
  margin-top: 100px;
}
</style>
